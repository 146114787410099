import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const router = new Router({
    mode: 'hash',
    // base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        // auth routes 
        { path: "*", redirect: { name: 'Login'} },
        // auth routes 
        {
            path: '/auth/',
            component: () => import('./layouts/AuthLayout.vue'),
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: () =>
                        import ('./views/pages/auth/Login.vue'),
                    meta: {
                        isAuthenticatedRoute: true,
                        title: "Login",
                    },
                },
            ]
        },

        //dashboard routes
        {
            path: '/',
            component: () => import('./layouts/Dasboard.vue'),
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: () =>
                        import ('./views/pages/dashboard/Index.vue'),
                    meta: {
                        isAuthenticatedRoute: false,
                        title: "Home"
                    },
                },
                // {
                //     path: 'clinics',
                //     name: 'Clinics',
                //     component: () =>
                //         import ('./views/pages/clinics/Index.vue'),
                //     meta: {
                //         isAuthenticatedRoute: false,
                //         title: "Clinics"
                //     },
                // },
                {
                    path: '/lessons/',
                    component: {
                        render: (c) => c('router-view'),
                    },
                    children: [
                        {
                            path: '',
                            name: 'lessons',
                            component: () =>
                                import ('./views/pages/lessons/Index.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "lessons"
                            },
                        },
                        {
                            path: 'add',
                            name: 'Add Lesson',
                            component: () =>
                                import ('./views/pages/lessons/Add.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Add Lesson"
                            },
                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Lesson',
                            component: () =>
                                import ('./views/pages/lessons/Edit.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Edit Lesson"
                            },
                        },
                        {
                            path: 'add/video/:id',
                            name: 'Add Lesson Video',
                            component: () =>
                                import ('./views/pages/lessons/AddVideo.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Add Lesson Video"
                            },
                        },
                        {
                            path: 'show/videos/:id',
                            name: 'Show Lesson Videos',
                            component: () =>
                                import ('./views/pages/lessons/ShowVideo.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Show Lesson Videos"
                            },
                        },
                        {
                            path: 'edit/video/:id',
                            name: 'Edit Lesson Video',
                            component: () =>
                                import ('./views/pages/lessons/EditVideo.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Edit Lesson Video"
                            },
                        },
                        {
                            path: 'view/:id',
                            name: 'Lesson Detail',
                            component: () =>
                                import ('./views/pages/lessons/View.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Lesson Details"
                            },
                        },
                    ]
                },
                {
                    path: '/plans/',
                    component: {
                        render: (c) => c('router-view'),
                    },
                    children: [
                        {
                            path: '',
                            name: 'plans',
                            component: () =>
                                import ('./views/pages/plans/Index.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Plans"
                            },
                        },
                        {
                            path: 'add',
                            name: 'Add Plan',
                            component: () =>
                                import ('./views/pages/plans/Add.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Add Plan"
                            },
                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Plan',
                            component: () =>
                                import ('./views/pages/plans/Edit.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Edit Plan"
                            },
                        },
                    ]
                },
                {
                    path: '/users/',
                    component: {
                        render: (c) => c('router-view'),
                    },
                    children: [
                        {
                            path: '',
                            name: 'Users',
                            component: () =>
                                import ('./views/pages/users/Index.vue'),
                            meta: {
                                isAuthenticatedRoute: false,
                                title: "Users"
                            },
                        },
                    ]
                }
            ]
        },
    ],
})

router.beforeEach((to, from, next) => {
    // for page title
    window.document.title = to.meta && to.meta.title ? "SharpBody-p Admin | " + to.meta.title : "Weightloss-p Admin";
    // authenticate routes
    var isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'));
    if(isLoggedin){
        if(!to.meta.isAuthenticatedRoute){
            next();
        } else{
            router.push('/dashboard');
        }
    } 
    else {
        if(to.meta.isAuthenticatedRoute){
            next();
        } 
        else {
            router.push('/auth/login');
        }
    }
    // next();

})
export default router
